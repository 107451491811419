<template>
  <div class="popup_wrap popup_wrap_pdf" style="width:800px;">
    <button type="button" class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.CMBA380.030') }} {{ $t('msg.CMBA380.031') }}</h1>
      <div class="content_box">
        <table class="tbl_col">
          <colgroup>
            <col width="25%">
            <col width="35%">
            <col width="40%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.CMBA100.00335') }}</th>
              <th>{{ $t('msg.ONEX010T010.150') }}<!-- TEL --></th>
              <th>{{ $t('msg.ONEX010T010.151') }}<!-- email --></th>
            </tr>
          </thead>
          <tbody>
            <template v-if="userList.length != 0">
              <tr v-for="(item, idx) in userList" :key="idx">
                <td class="text_left">{{ item.usrNm }}</td>
                <td class="text_left">{{ item.usrPhone }}</td>
                <td class="text_left">{{ item.usrEmail }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="99">{{ $t('msg.CMBA380.029') }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" @click="$emit('close')">{{ $t('msg.ADMN040G010.032') }}<!-- 닫기 --></a>
      </div>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import myRequest from '@/api/rest/trans/myRequest'
import commonUtils from '@/plugins/commonUtils'

export default {
  name: 'MyRequestUserPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    //userList 일단 여러개 나올수 있다고는 하여 배열로 정의
    return {
      apvUno: '',
      bizCatCd: '',
      userList: [],
      mailList: [
        'DOCCA@EKMTC.COM',
        'DOCIS@EKMTC.COM',
        'DOCEDI@EKMTC.COM',
        'DOCCLL@EKMTC.COM',
        'DOCID@EKMTC.COM',
        'CSC@EKMTC.COM',
        'DESKSEL@EKMTC.COM'
      ],
      phoneList: [
        '',
        '051-797-7400(내선2)',
        '051-797-7400(내선3)',
        '051-797-7400(내선4)',
        '051-797-7400(내선5)',
        '051-797-7400(내선6)',
        '02-311-6114',
        '02-311-6274/6286/6292'
      ],
      teamList: []
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.teamList = [
        { usrNm: 'C/A팀', usrPhone: this.phoneList[1], usrEmail: this.mailList[0] },
        { usrNm: '발급팀', usrPhone: this.phoneList[5], usrEmail: this.mailList[1] },
        { usrNm: '수입팀', usrPhone: this.phoneList[4], usrEmail: this.mailList[4] },
        { usrNm: '신고팀', usrPhone: this.phoneList[2], usrEmail: this.mailList[2] },
        { usrNm: '마감팀', usrPhone: this.phoneList[3], usrEmail: this.mailList[3] },
        { usrNm: '고객서비스팀', usrPhone: this.phoneList[6], usrEmail: this.mailList[5] },
        { usrNm: '본사 데스크', usrPhone: this.phoneList[7], usrEmail: this.mailList[6] }
      ]

      if (this.$props.parentInfo !== undefined) {
        this.setItems(this.$props.parentInfo)
      }
    },
    async setItems (rvo) {
       console.log('setItems rvo >>> ', rvo)
      if (rvo.apvStsNm === '처리중') {
        let catCd = rvo.bizCatCd
        let flag = true

        if (catCd === 'DA01' || catCd === 'RA01' || catCd === 'RA02' || catCd === 'RA03' || catCd === 'RE12' || catCd === 'FR01' || catCd === 'MI01') {
          flag = await this.userSalListFnc(rvo, 'sal', catCd.substring(0, 2))
        } else {
          flag = await this.userIngListFnc(rvo)
        }

        if (!flag) {
            this.userList.push(this.teamList[0])
        }
      } else {
        //[TO-DO]하드코딩 되어 있는거 나중에 DB로 다 변경 필요하다.
        const catCd = rvo.bizCatCd
        if (catCd === 'CA01' || catCd === 'CE01') {
          if ((catCd === 'CA01' || rvo.apvStsNm === '취소') && this.memberDetail.userCtrCd !== 'KR') {
            this.userIngListFnc(rvo)
          } else {
            const flag = await this.userListFnc(rvo, this.mailList[0])
            if (!flag) {
              this.userList.push(this.teamList[0])
            }
          }
        } else if (catCd === 'DA01') {
          const flag = await this.userSalListFnc(rvo, 'bl', catCd.substring(0, 2))
          if (!flag) {
            this.userList.push(this.teamList[1])
          }
        } else if (catCd === 'DA02' || catCd === 'DA03' || catCd === 'DA06' || catCd === 'RE08') {
          if (this.memberDetail.userCtrCd !== 'KR' && catCd === 'RE08') {
            rvo.ntcCd = '01'
            const flag = await this.userIngListFnc(rvo)
            if (!flag) {
              this.userList.push(this.teamList[1])
            }
          } else if (this.memberDetail.userCtrCd !== 'KR' && catCd === 'DA03' && rvo.apvUno === 'KAI') {
             rvo.ntcCd = '06'
             const flag = await this.userIngListFnc(rvo)
             if (!flag) {
              this.userList.push(this.teamList[1])
             }
          } else {
            const flag = await this.userListFnc(rvo)
            if (!flag) {
              this.userList.push(this.teamList[1])
            }
          }
        } else if (catCd === 'DA05') {
          const flag = await this.userListFnc(rvo, this.mailList[1])
          if (!flag) {
            this.userList.push(this.teamList[2])
          }
        } else if (catCd === 'DA07') {
          const flag = await this.userListFnc(rvo, this.mailList[1])
          if (!flag) {
            this.userList.push(this.teamList[1])
          }
        } else if (catCd === 'DA14') {
          if (rvo.apvStsNm === '승인' || rvo.apvStsNm === '거절') {
            const flag = await this.userListFnc(rvo)
            if (!flag) {
              this.userList.push(this.teamList[6])
            }
          } else {
            this.userList.push(this.teamList[6])
          }
        } else if (catCd === 'RE05') {
          this.userList.push(this.teamList[3])
        } else if (catCd === 'RE06') {
          const flag = await this.userListFnc(rvo, 'DOCSR@EKMTC.COM')
          if (!flag) {
            this.userList.push('DOCSR@EKMTC.COM')
          }
        } else if (catCd === 'RE01' || catCd === 'RE02' || catCd === 'RE16') {
          let flag = this.porPlcFnc(rvo.porPlcCd)

          if (!flag) {
            flag = await this.userListFnc(rvo)
            if (!flag) {
              this.userList.push(this.teamList[4])
            }
          }
        } else if (catCd === 'RE01' || catCd === 'RE02' || catCd === 'RE14' || catCd === 'RE15') {
          this.userList.push(this.teamList[4])
        } else if (catCd === 'FR01' || catCd === 'RA01' || catCd === 'RA02' || catCd === 'RA03' || catCd === 'RE12' || catCd === 'MI01') {
          this.userSalListFnc(rvo, 'sal', catCd.substring(0, 2))
        } else if (catCd === 'DA15' || catCd === 'DA16' || catCd === 'DA17' || catCd === 'DA18' || catCd === 'DA09' || catCd === 'DA10' || catCd === 'DA99') { // 2022.05.17 DEM, DET, DFC 일때 담당자 변경 추가
          this.userListFnc(rvo)
        } else if (catCd === 'WA01') {
          //DSN : 대산, KPO : 포항, KAN : 광양
          const flag = this.porPlcFnc(rvo.porPlcCd)
          if (!flag) {
            this.userList.push(this.teamList[2])
          }
        } else if (catCd === 'WA02') {
          this.userList.push(this.teamList[5])
        }
      }
    },
    async userListFnc (rvo, email) {
      let isFlag = false

      if (!rvo.apvUno) {
        if (rvo.bizCatCd === 'DA17') { // OFC이고, 처리중일때
          this.userList.push(this.teamList[1])
        }

        return isFlag
      }
      const THIS = this
      await myRequest.getUserInfo({ apvUno: rvo.apvUno }).then(res => {
        const vo = res.data
        if (vo) {
          THIS.userList.push({
            usrNm: (vo.usrNm !== undefined ? vo.usrNm : vo.usrEnm),
            usrPhone: vo.usrPhone,
            // usrEmail: (rvo.bizCatCd === 'DA05' ? email : vo.usrEmail !== undefined ? vo.usrEmail : email)
            usrEmail: this.$ekmtcCommon.isEmpty(email) ? vo.usrEmail : this.memberDetail.userCtrCd !== 'KR' ? vo.usrEmail : email
          })
          isFlag = true
        }
      })
      return isFlag
    },
    async userSalListFnc (rvo, type, catKind) {
      let blRaNo = ''

      if (rvo.bizCatCd === 'RA01' || rvo.bizCatCd === 'RA02' || rvo.bizCatCd === 'RA03') { // 운임문의,연장, 네고 이면 R/A No 넘김
        blRaNo = rvo.raNo
      } else {
        blRaNo = rvo.blNo
      }

      let isFlag = false
      let cstCd = this.memberDetail.cstCd
      if (type === 'bl') {
        cstCd = ''
      }
      const THIS = this
      await myRequest.getUserSalInfo({ blNo: blRaNo, catKind: catKind }).then(res => {
        const vo = res.data
        if (vo) {
          THIS.userList = vo.myRequestUserList
          isFlag = true
        }
      })
      return isFlag
    },
    async userIngListFnc (rvo) {
      console.log('userIngListFnc rvo >>> ', rvo)
      let isFlag = false

      const THIS = this
      await myRequest.getUserIngInfo({ blNo: rvo.blNo, ntcCd: rvo.ntcCd }).then(res => {
        const vo = res.data
        if (vo) {
          THIS.userList = vo.myRequestUserList
          isFlag = true
        }
      })
      return isFlag
    },
    porPlcFnc (polPlc) {
      //DSN : 대산, KPO : 포항, KAN : 광양
      let isFlag = false
      if (!polPlc) {
        return isFlag
      }
      const rtnObj = commonUtils.plcFnc(polPlc, '01')

      if (!this.$ekmtcCommon.isEmpty(rtnObj.usrNm)) {
        this.userList.push(rtnObj)
        isFlag = true
      }
      return isFlag
    }
  }
}
</script>

<style>
body {
  min-width:100% !important;
}
</style>
